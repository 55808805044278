import React from "react";

export default {
    backend: {
        host: "https://the-politics-of-covid-19.com/search/api",
        index: "museum"
    },
    header: {
        logo: null,
        title: <a href="https://archive.designing.place/" style={{color: "inherit", textDecoration: "inherit"}}>The Designing Place Archive</a>,
        intro: <span>{"Seeking original sources for truth to support museological traditions in the diffusion of knowledge."}</span>,
        extra_logos: <div className="header_img_syllabus_wrapper"><span className="header_poweredby">Presented with</span><a target='_blank' rel="noopener noreferrer" href="http://www.raany.com/"><img className="header_img_syllabus header_img_museum" src={'img/museum/logo-large.png'} alt="RAA"/></a></div> 
    }
};
